<mat-toolbar class="mat-toolbar nav-toolbar">
  <mat-toolbar-row fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <h1 class="title" (click)="isAuthenticated && navigateHome()">
        <strong>{{ title }}</strong>
      </h1>
      <ng-container *ngIf="isAuthenticated">
        <a mat-button routerLink="/story-search" routerLinkActive="active-link"> Search Stories </a>
        <a mat-button routerLink="/my-stories" routerLinkActive="active-link"> My Stories </a>
      </ng-container>
    </div>
    <div *ngIf="isAuthenticated" fxLayoutAlign="space-around center" fxShow.lt-lg="false" fxShow="true">
      <ng-container *ngTemplateOutlet="extraLinks"></ng-container>
      <button mat-icon-button [matMenuTriggerFor]="menu" [title]="authenticationDetails" fxLayoutAlign="end">
        <i class="material-icons">person</i>
      </button>
    </div>
    <div *ngIf="isAuthenticated" fxShow.lt-lg="true" fxShow="false">
      <button mat-icon-button [matMenuTriggerFor]="menu" [title]="authenticationDetails">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>

  <mat-menu #menu="matMenu">
    <section *ngIf="isAdmin">
      <a mat-menu-item routerLink="/tag-management" routerLinkActive="active-link"> Tag Management </a>
      <a mat-menu-item href="/(fullwidth:export)" routerLinkActive="active-link"> Value Creation Leads </a>
      <a mat-menu-item [href]="hangfireUrl" target="_blank"> Hangfire Dashboard </a>
      <a mat-menu-item href="/add-release-note">Add Release Notes </a>
      <hr />
    </section>
    <section fxShow.lt-lg="true" fxShow="false">
      <a mat-menu-item href="https://it.purple.tech" target="_blank" rel="noopener noreferrer"> Support/Feedback </a>
      <a mat-menu-item routerLink="/about" routerLinkActive="active-link">About Page</a>
      <a mat-menu-item routerLink="/release-notes" routerLinkActive="active-link">Release Notes</a>
      <hr />
    </section>
    <a mat-menu-item (click)="logout()"> Logout </a>
  </mat-menu>
</mat-toolbar>

<mat-menu #aboutMenu="matMenu">
  <section>
    <a mat-menu-item routerLink="/about" routerLinkActive="active-link">About Page</a>
    <a mat-menu-item routerLink="/release-notes" routerLinkActive="active-link">Release Notes</a>
  </section>
</mat-menu>

<ng-template #extraLinks>
  <a mat-button [matMenuTriggerFor]="aboutMenu" fxLayoutAlign="end">ABOUT</a>
  
  <a mat-button href="https://it.purple.tech" target="_blank" rel="noopener noreferrer"> Support/Feedback </a>
</ng-template>
