import { NgxTextOverflowClampModule } from '../shared/components/clamp/clamp.module';
import { SharedModule } from '../shared/shared.module';
import { AttachmentItemComponent } from './components/attachment-item/attachment-item.component';
import { ChangeRevisionDialogComponent } from './components/change-revision/change-revision-dialog.component';
import { DeleteAttachmentDialogComponent } from './components/delete-attachment-dialog/delete-attachment-dialog.component';
import { DeleteStoryDialogComponent } from './components/delete-story-dialog/delete-story-dialog.component';
import { EditStoryAttachmentsComponent } from './components/edit-story-attachments/edit-story-attachments.component';
import { EditStoryLinksComponent } from './components/edit-story-links/edit-story-links.component';
import { EditStoryPartDialogComponent } from './components/edit-story-part/edit-story-part-dialog.component';
import { EditStoryTitleDialogComponent } from './components/edit-story-title/edit-story-title-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HomeComponent } from './components/home/home.component';
import { MyOwnStoriesComponent } from './components/my-own-stories/my-own-stories.component';
import { NewStoryDialogComponent } from './components/new-story/new-story-dialog.component';
import { ShareStoryDialogComponent } from './components/share-story-dialog/share-story-dialog.component';
import { StoriesComponent } from './components/stories/stories.component';
import { StoryDisplayItemComponent } from './components/story-display-item/story-display-item.component';
import { CaseStudyNominationEditModeComponent } from './components/story-meta/case-study-nomination-edit-mode/case-study-nomination-edit-mode.component';
import { CaseStudyNominationViewModeComponent } from './components/story-meta/case-study-nomination-view-mode/case-study-nomination-view-mode.component';
import { ContributorsEditModeComponent } from './components/story-meta/contributors-edit-mode/contributors-edit-mode.component';
import { ContributorsListComponent } from './components/story-meta/contributors-list/contributors-list.component';
import { ContributorsViewModeComponent } from './components/story-meta/contributors-view-mode/contributors-view-mode.component';
import { MetaSectionComponent } from './components/story-meta/meta-section/meta-section.component';
import { OwnerEditModeComponent } from './components/story-meta/owner-edit-mode/owner-edit-mode.component';
import { OwnerViewModeComponent } from './components/story-meta/owner-view-mode/owner-view-mode.component';
import { PhaseEditModeComponent } from './components/story-meta/phase-edit-mode/phase-edit-mode.component';
import { StateEditModeComponent } from './components/story-meta/state-edit-mode/state-edit-mode.component';
import { StoryMetaComponent } from './components/story-meta/story-meta.component';
import { TagsEditModeComponent } from './components/story-meta/tags-edit-mode/tags-edit-mode.component';
import { TagsViewModeComponent } from './components/story-meta/tags-view-mode/tags-view-mode.component';
import { VisibilityEditModeComponent } from './components/story-meta/visibility-edit-mode/visibility-edit-mode.component';
import { StoryPhaseNoteComponent } from './components/story-phase-note/story-phase-note.component';
import { StorySearchIframeComponent } from './components/story-search-iframe/story-search-iframe.component';
import { StorySearchFilterComponent } from './components/story-search/story-search-filter/story-search-filter.component';
import { StorySearchResultListComponent } from './components/story-search/story-search-result-list/story-search-result-list.component';
import { StorySearchComponent } from './components/story-search/story-search.component';
import { StoryStrengthComponent } from './components/story-strength/story-strength.component';
import { StoryTagAutocompleteComponent } from './components/story-tag-autocomplete/story-tag-autocomplete.component';
import { StoryTagComponent } from './components/story-tag/story-tag.component';
import { StoryVisibilityBadgeComponent } from './components/story-visibility-badge/story-visibility-badge.component';
import { UpdateOwnerDialogComponent } from './components/update-owner/update-owner-dialog.component';
import { ViewLikesDialogComponent } from './components/view-likes/view-likes-dialog.component';
import { ViewStoryAttachmentsComponent } from './components/view-story-attachments/view-story-attachments.component';
import { ViewStoryLinksComponent } from './components/view-story-links/view-story-links.component';
import { ViewStoryPartComponent } from './components/view-story-part/view-story-part.component';
import { ViewStoryReadonlyDialogComponent } from './components/view-story-readonly-dialog/view-story-readonly-dialog.component';
import { ViewStoryComponent } from './components/view-story/view-story.component';
import { StoriesService } from './services/stories.service';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule as MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { MatCheckboxModule as MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule as MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatListModule as MatListModule } from '@angular/material/list';
import { MatMenuModule as MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule as MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule as MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ViewStoryLinkComponent } from './components/view-story-link/view-story-link.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconComponent } from './components/icon/icon.component';
import { QuillModule } from 'ngx-quill';
import { SharingConditionsEditModeComponent } from './components/story-meta/sharing-conditions-edit-mode/sharing-conditions-edit-mode.component';
import { MatQuillModule } from './components/mat-quill/mat-quill-module';
import { CardItemComponent } from './components/card-item/card-item.component';
import { CreateStoryButtonComponent } from './components/create-story-button/create-story-button.component';
import { StoryActiveFiltersComponent } from './components/story-search/story-active-filters/story-active-filters.component';
import { StoryClientsSelectionComponent } from './components/story-search/story-clients-selection/story-clients-selection.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatOptionModule } from '@angular/material/core';

@NgModule({
  declarations: [
    HomeComponent,
    StoriesComponent,
    NewStoryDialogComponent,
    ViewStoryComponent,
    ViewStoryPartComponent,
    StoryMetaComponent,
    EditStoryTitleDialogComponent,
    EditStoryPartDialogComponent,
    DeleteStoryDialogComponent,
    MyOwnStoriesComponent,
    UpdateOwnerDialogComponent,
    ChangeRevisionDialogComponent,
    StoryTagComponent,
    StorySearchComponent,
    StorySearchFilterComponent,
    StoryDisplayItemComponent,
    StoryPhaseNoteComponent,
    FileUploadComponent,
    AttachmentItemComponent,
    ViewStoryReadonlyDialogComponent,
    DeleteAttachmentDialogComponent,
    ShareStoryDialogComponent,
    StoryVisibilityBadgeComponent,
    ViewLikesDialogComponent,
    StoryTagAutocompleteComponent,
    OwnerViewModeComponent,
    OwnerEditModeComponent,
    ContributorsEditModeComponent,
    ContributorsViewModeComponent,
    ContributorsListComponent,
    MetaSectionComponent,
    TagsViewModeComponent,
    TagsEditModeComponent,
    PhaseEditModeComponent,
    StateEditModeComponent,
    VisibilityEditModeComponent,
    StoryStrengthComponent,
    StorySearchResultListComponent,
    StorySearchIframeComponent,
    CaseStudyNominationEditModeComponent,
    CaseStudyNominationViewModeComponent,
    ViewStoryLinksComponent,
    EditStoryLinksComponent,
    ViewStoryAttachmentsComponent,
    EditStoryAttachmentsComponent,
    ViewStoryLinkComponent,
    IconComponent,
    CardItemComponent,
    SharingConditionsEditModeComponent,
    CreateStoryButtonComponent,
    StoryActiveFiltersComponent,
    StoryClientsSelectionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatAutocompleteModule,
    FontAwesomeModule,
    MatCardModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatCardModule,
    MatExpansionModule,
    MatTooltipModule,
    MatOptionModule,
    ReactiveFormsModule,
    RouterModule,
    MatSelectModule,
    MatDividerModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatMenuModule,
    NgxTextOverflowClampModule,
    FlexLayoutModule,
    QuillModule.forRoot({
      modules: {
        'emoji-toolbar': true,
        toolbar: [
          ['bold', 'italic'],
          ['blockquote'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ header: [1, 2, false] }],
          ['emoji'],
          ['clean'],
          ['link'],
        ],
      },
    }),
    MatQuillModule,
    FontAwesomeModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
  providers: [StoriesService, { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StoryModule {}
